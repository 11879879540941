<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Kliniker</v-card-title>
      <v-card-text>
        <v-row justify="center" class="mb-4">
          <v-col cols="12" sm="6" md="3" lg="2">
            <v-autocomplete
              v-model="selectedCity"
              :items="cityOptions"
              label="Ort"
              clearable
              dense
              outlined
              @change="handleFilterChange"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <v-autocomplete
              v-model="selectedSpecialist"
              :items="specialistOptions"
              label="Specialist"
              clearable
              dense
              outlined
              @change="handleFilterChange"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <v-autocomplete
              v-model="selectedWaitTime"
              :items="waitTimeOptions"
              label="Väntetid"
              clearable
              dense
              outlined
              @change="handleFilterChange"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-fade-transition>
          <v-overlay :value="loadingKliniker" absolute color="white">
            <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
          </v-overlay>
        </v-fade-transition>

        <v-alert v-if="klinikerError" type="error" dismissible>
          {{ klinikerError }}
        </v-alert>

        <v-data-table
          v-if="showTable && filteredItems.length"
          :headers="headers"
          :items="paginatedKliniker"
          :options.sync="tableOptions"
          :server-items-length="totalItems"
          :footer-props="{
            itemsPerPageOptions: [5, 10],
            itemsPerPageText: 'Rader per sida:'
          }"
          class="elevation-1"
          :loading="loadingKliniker"
          dense
        >
          <template v-slot:item.till_kliniken="{ item }">
            <v-btn color="#383e42" class="white--text" @click="goToKlinik(item.id)" small>MER INFO</v-btn>
          </template>

          <template v-slot:no-data>
            Inga kliniker hittades med de valda filtren.
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: "Kliniker",
  data() {
    return {
      cityOptions: [
        { text: 'Anderslöv', value: 'Anderslöv' },
        { text: 'Arlöv', value: 'Arlöv' },
        { text: 'Bara', value: 'Bara' },
        { text: 'Bjärnum', value: 'Bjärnum' },
        { text: 'Bjärred', value: 'Bjärred' },
        { text: 'Bjuv', value: 'Bjuv' },
        { text: 'Borrby', value: 'Borrby' },
        { text: 'Broby', value: 'Broby' },
        { text: 'Bromölla', value: 'Bromölla' },
        { text: 'Brösarp', value: 'Brösarp' },
        { text: 'Båstad', value: 'Båstad' },
        { text: 'Bunkeflostrand', value: 'Bunkeflostrand' },
        { text: 'Dalby', value: 'Dalby' },
        { text: 'Degeberga', value: 'Degeberga' },
        { text: 'Ekeby', value: 'Ekeby' },
        { text: 'Eslöv', value: 'Eslöv' },
        { text: 'Förslöv', value: 'Förslöv' },
        { text: 'Genarp', value: 'Genarp' },
        { text: 'Helsingborg', value: 'Helsingborg' },
        { text: 'Höganäs', value: 'Höganäs' },
        { text: 'Hjärup', value: 'Hjärup' },
        { text: 'Häljarp', value: 'Häljarp' },
        { text: 'Höllviken', value: 'Höllviken' },
        { text: 'Höör', value: 'Höör' },
        { text: 'Hörby', value: 'Hörby' },
        { text: 'Hässleholm', value: 'Hässleholm' },
        { text: 'Åhus', value: 'Åhus' },
        { text: 'Åkarp', value: 'Åkarp' },
        { text: 'Kivik', value: 'Kivik' },
        { text: 'Klippan', value: 'Klippan' },
        { text: 'Knislinge', value: 'Knislinge' },
        { text: 'Kristianstad', value: 'Kristianstad' },
        { text: 'Kävlinge', value: 'Kävlinge' },
        { text: 'Landkrona', value: 'Landkrona' },
        { text: 'Landskrona', value: 'Landskrona' },
        { text: 'Löberöd', value: 'Löberöd' },
        { text: 'Löddeköpinge', value: 'Löddeköpinge' },
        { text: 'Limhamn', value: 'Limhamn' },
        { text: 'Ljungbyhed', value: 'Ljungbyhed' },
        { text: 'Lomma', value: 'Lomma' },
        { text: 'Lund', value: 'Lund' },
        { text: 'Malmö', value: 'Malmö' },
        { text: 'Munka-Ljungby', value: 'Munka-Ljungby' },
        { text: 'Ängelholm', value: 'Ängelholm' },
        { text: 'Osby', value: 'Osby' },
        { text: 'Oxie', value: 'Oxie' },
        { text: 'Påarp', value: 'Påarp' },
        { text: 'Perstorp', value: 'Perstorp' },
        { text: 'Rö', value: 'Rö' },
        { text: 'Örkelljunga', value: 'Örkelljunga' },
        { text: 'Rydebäck', value: 'Rydebäck' },
        { text: 'Södra Sandby', value: 'Södra Sandby' },
        { text: 'Simrishamn', value: 'Simrishamn' },
        { text: 'Sjöbo', value: 'Sjöbo' },
        { text: 'Skivarp', value: 'Skivarp' },
        { text: 'Skurup', value: 'Skurup' },
        { text: 'Staffansstorp', value: 'Staffansstorp' },
        { text: 'Staffanstorp', value: 'Staffanstorp' },
        { text: 'Åstorp', value: 'Åstorp' },
        { text: 'Svalöv', value: 'Svalöv' },
        { text: 'Svedala', value: 'Svedala' },
        { text: 'Tollarp', value: 'Tollarp' },
        { text: 'Tomelilla', value: 'Tomelilla' },
        { text: 'Trelleborg', value: 'Trelleborg' },
        { text: 'Tyringe', value: 'Tyringe' },
        { text: 'Veberöd', value: 'Veberöd' },
        { text: 'Vellinge', value: 'Vellinge' },
        { text: 'Vinslöv', value: 'Vinslöv' },
        { text: 'Ystad', value: 'Ystad' }
      ],
      specialistOptions: [
        'BMM', 'BVC', 'Hud', 'Katarakt', 'LARO', 'Sjukgymnast', 'Vårdcentral', 'Ögon'
      ],
      waitTimeOptions: [
        { text: '1 - 4 Veckor', value: '1-4_weeks' },
        { text: '1 - 2 Månader', value: '1-2_months' },
        { text: '2 - 3 Månader', value: '2-3_months' }
      ],
      loadingKliniker: false,
      klinikerError: null,
      showTable: false,
      headers: [
        { text: 'Ort', value: 'ort', width: '20%' },
        { text: 'Specialist', value: 'behandling', width: '30%' },
        { text: 'Väntetid', value: 'vTid', width: '20%' },
        { text: 'Till kliniken', value: 'till_kliniken', sortable: false, width: '10%' }
      ],
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: []
      }
    };
  },
  computed: {
    ...mapState(['kliniker', 'searchParams']),
    selectedCity: {
      get() { return this.searchParams.selectedCity; },
      set(value) { this.updateSearchParam('selectedCity', value); }
    },
    selectedSpecialist: {
      get() { return this.searchParams.selectedSpecialist; },
      set(value) { this.updateSearchParam('selectedSpecialist', value); }
    },
    selectedWaitTime: {
      get() { return this.searchParams.selectedWaitTime; },
      set(value) { this.updateSearchParam('selectedWaitTime', value); }
    },
    filteredItems() {
      return this.kliniker.filter(klinik => 
        this.matchesCity(klinik.ort) &&
        this.matchesSpecialist(klinik.behandling) &&
        this.matchesWaitTime(klinik.vTid)
      );
    },
    totalItems() {
      return this.filteredItems.length;
    },
    paginatedKliniker() {
      const start = (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
      const end = start + this.tableOptions.itemsPerPage;
      return this.filteredItems.slice(start, end);
    }
  },
  methods: {
    ...mapActions(['getClinics', 'setSearchParams']),
    updateSearchParam(param, value) {
      this.setSearchParams({ ...this.searchParams, [param]: value });
    },
    async fetchClinics() {
      this.loadingKliniker = true;
      this.klinikerError = null;
      this.showTable = false;
      try {
        const response = await this.getClinics();
        if (response && response.data) {
          this.showTable = true;
        } else {
          throw new Error('Invalid response structure');
        }
      } catch (error) {
        console.error('Error fetching kliniker:', error);
        this.klinikerError = 'Kunde inte hämta kliniker. Vänligen försök igen.';
      } finally {
        this.loadingKliniker = false;
      }
    },
    goToKlinik(id) {
      if (id) {
        this.$router.push({ name: 'KlinikDetail', params: { id } });
      } else {
        console.error('Invalid klinik ID');
      }
    },
    matchesCity(city) {
      return !this.selectedCity || city.trim().toLowerCase() === this.selectedCity.trim().toLowerCase();
    },
    matchesSpecialist(specialist) {
      return !this.selectedSpecialist || specialist.trim().toLowerCase().includes(this.selectedSpecialist.trim().toLowerCase());
    },
    matchesWaitTime(waitTime) {
      if (!this.selectedWaitTime) return true;
      const waitTimeMap = {
        '1-4_weeks': '1 - 4 Veckor',
        '1-2_months': '1 - 2 Månader',
        '2-3_months': '2 - 3 Månader'
      };
      return waitTime === waitTimeMap[this.selectedWaitTime];
    },
    handleFilterChange: _.debounce(function() {
      this.fetchClinics();
    }, 300)
  },
  watch: {
    tableOptions: {
      handler() {
        this.updateSearchParam('page', this.tableOptions.page);
        this.updateSearchParam('itemsPerPage', this.tableOptions.itemsPerPage);
      },
      deep: true
    }
  },
  mounted() {
    this.fetchClinics();
  }
}
</script>

<style scoped>
.v-data-table ::v-deep .v-data-table__wrapper {
  overflow-x: auto;
}
</style>